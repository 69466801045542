$(document).ready(function() {


    //Filter uses

    var $btns = $('.box-utilizzi').click(function(e) {
        e.preventDefault();
        if (this.id == 'item-util-all') {
            $('#prod-container .item').fadeIn(450);
            $('.item:hidden').parent().show();
        } else {
            $('.item:hidden').parent().not($el).show();
            var $el = $('.' + this.id).fadeIn(450);
            $('#prod-container .item').not($el).hide();
            $('.item:hidden').parent().hide();
        }
        $btns.removeClass('active');
        $(this).addClass('active');
    });

    function random(owlSelector) {
        owlSelector.children().sort(function() {
            return Math.round(Math.random()) - 0.5;
        }).each(function() {
            $(this).appendTo(owlSelector);
        });
    }

    // Owl Carousel init
    var $owl_main = $('#home-owlslide-main');
    $owl_main.on('initialize.owl.carousel', function(event) {
        var selector = $('#home-owlslide-main');
        random(selector);
    });

    $('#home-owlslide-main').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        autoplay: false,
        autoplayTimeout: 7000,
        items: 1
    });

    $('#home-owlslide-sections').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 7000,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            },
            1400: {
                items: 3
            }
        }
    });

    $('#home-owlslide-applications').owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            }
        }
    });

    $('#home-owlslide-insight').owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 7000,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            }
        }
    });

    $('#home-owlslide-news').owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 7000,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            }
        }
    });

    $('#slider-about-management, #slider-about-sales, #slider-about-customercare').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        dots: false,
        autoplay: true,
        autoplayTimeout: 7000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            800: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    });

    $('#prod-video').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: false,
        video: true,
        lazyLoad: true,
        items: 1
    });

    $('#related-prod').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        dots: false,
        autoplay: true,
        autoplayTimeout: 4000,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1400: {
                items: 3
            }
        }
    });

    $('#home-ec-focus-popular, #home-ec-focus-new, #home-ec-focus-end').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 3
            }
        }
    });

    var slider = $('#slider');
    var thumbnailSlider = $('#thumbnailSlider');

    slider.owlCarousel({
        loop: false,
        nav: false,
        items: 1
    }).on('changed.owl.carousel', function(e) {
        //On change of main item to trigger thumbnail item
        thumbnailSlider.trigger('to.owl.carousel', [e.item.index, 500, true]);
    });

    // carousel function for thumbnail slider
    thumbnailSlider.owlCarousel({
        loop: false,
        center: true,
        nav: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 4
            },
            1000: {
                items: 6
            }
        }
    }).on('click', '.owl-item', function() {
        // On click of thumbnail items to trigger same main item
        slider.trigger('to.owl.carousel', [$(this).index(), 500, true]);
    }).on('changed.owl.carousel', function(e) {
        // On change of thumbnail item to trigger main item
        slider.trigger('to.owl.carousel', [e.item.index, 500, true]);
    });

    // Navigation for main items
    $('.slider-right').click(function() {
        slider.trigger('next.owl.carousel');
    });
    $('.slider-left').click(function() {
        slider.trigger('prev.owl.carousel');
    });


    // Masonry

    $('#masonry').masonry({
        // set itemSelector so .grid-sizer is not used in layout
        itemSelector: '.masonry-item',
        // use element for option
        columnWidth: '.masonry-sizer',
        gutter: '.gutter-sizer',
        percentPosition: true
    });


    //Checkout form

    $('#ship-different').click(function() {
        if ($(this).is(':checked')) {
            $('.hiddenship').removeClass('hidden');
        } else {
            $('.hiddenship').addClass('hidden');
        }
    });


    // YAMM - prevent unexpected menu close when using some components (like accordion, forms, etc)

    $(document).on('click', '.yamm .dropdown-menu', function(e) {
        e.stopPropagation();
    });


    // Match height

    $('#home-owlslide-applications .item-owlslide').matchHeight();
    $('#home-owlslide-sections .item-owlslide').matchHeight();


    // Menu sidebar

    $('#sidebar-nav .panel-collapse').on('hidden.bs.collapse', function() {
        $(this).prev().find('a.toggle-menu').text('+');
        $(this).prev().find('a.side-nav-cat-title').removeClass('active');
    });
    $('#sidebar-nav .panel-collapse').on('shown.bs.collapse', function() {
        $(this).prev().find('a.toggle-menu').text('-');
        $(this).prev().find('a.side-nav-cat-title').addClass('active');
    });



    // Scroll to top

    function detectPosition(top) {
        if ($(window).scrollTop() > top) {
            return 1
        } else {
            return 0;
        }
    }

    function fadeTop(x) {
        if (x == 1) {
            $('#top').fadeIn(300)
        } else {
            $('#top').fadeOut(300)
        }
    }

    function actionTop(top) {
        x = detectPosition(top);
        fadeTop(x)
    }

    if ($('#top').length > 0) {

        var top = 220;
        var x = 0;
        actionTop(top)

        $(window).scroll(function() {
            actionTop(top)
        })

        $('#top').click(function(e) {
            e.preventDefault();
            $('html,body').animate({ 'scrollTop': 0 }, 750);
        });
    };

});